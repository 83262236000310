interface IEventData {
  campaign_id: number;
  banner_id: number;
  app_id: string;
  place_slug: string;
}

export interface ILitresBanner {
  url: string;
  title: string;
  link?: string;
  mark?: string;
  copyLink?: string;
  event_data: IEventData;
}

export interface IBannerData {
  banner_title: string;
  image_url: string;
  transition_link: string;
  advertising_marking?: string;
  event_data: IEventData;
}

export enum EPlaceSlug {
  NoSlug = '',
  HeaderFullWidth = 'selfpub-header-full-width',
  ServicesPromo = 'selfpub-services-promo',
  Services = 'selfpub-services',
  MyBooks = 'selfpub-mybooks',
  Edit = 'selfpub-services-edit',
  Proofread = 'selfpub-services-pread',
  Pagemaker = 'selfpub-services-pmaker',
  Annotation = 'selfpub-services-anno',
  Assistant = 'selfpub-services-assist',
  Design = 'selfpub-services-cover',
  Booktrailer = 'selfpub-services-bookt',
  Audiobook = 'selfpub-services-audio',
  Main = 'selfpub-main',
}
